@import "../../styles/variables.scss";

.carouselWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $yellow;
  height: auto;
  overflow: hidden;
  padding-bottom: 100px;
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    padding: 20px 0;
    p {
      color: var(--loop-blk);
      text-align: center;
      color: #1b1b1b;
      @include regular-p;
      a {
        text-decoration: underline;
        font-family: $maax-bold;
      }
    }
  }
  .carousel {
    position: relative;
    width: 100%;
    height: 140px;
    border-top: 1px solid #1b1b1b;
    border-bottom: 1px solid #1b1b1b;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff2c 0% 0% no-repeat padding-box;

    .leftArrow {
      position: absolute;
      width: 125px;
      height: 35px;
      background-color: black;
      padding: 15px;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      svg {
        margin-right: -10px;
        height: 20px;
      }
    }
    .items {
      position: relative;
      width: calc(100% - 200px);

      .item {
        position: relative;
        width: 10%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .rightArrow {
      position: absolute;
      right: 0;
      width: 125px;
      height: 35px;
      background-color: black;
      padding: 15px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      svg {
        margin-right: 70px;
        transform: rotate(180deg);
        height: 20px;
      }
    }
  }
}

@media all and (max-width: 767px) {
  .carouselWrapper {
    .header {
      width: 80%;
      margin: 0 auto;
    }
    .carousel {
      .items {
        .item {
          position: relative;
          height: 60px;
          width: 100%;
        }
      }
    }
  }
}
