@import "../../styles/variables.scss";

.restaurantWrapper {
  position: absolute;
  height: calc(100vh - 120px);
  min-height: 700px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .bg,
  .fg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    img,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .fg {
    z-index: 3;
    pointer-events: none;
    img,
    video {
      width: 140%;
      margin-left: -20%;
    }
    .restaurantFg1 {
      position: absolute;
      height: 100%;
      width: 100%;
      min-height: 750px;
      right: 42%;
    }
    .restaurantFg2 {
      position: absolute;
      height: 100%;
      width: 100%;
      min-height: 750px;
      left: 42%;
    }
  }
}

@media all and (max-width: 896px) {
  .restaurantWrapper {
    .fg {
      display: none;
    }
  }
}