@import "../../styles/variables.scss";

.heroWrapper {
  position: relative;
  height: calc(100vh - 120px);
  min-height: 700px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .tvFrameWrap {
    position: relative;
    z-index: 5;
    width: 60%;
    max-width: 1100px;
    min-width: 500px;
    margin-left: 25px;
    z-index: 3;
    .tvBackground {
      position: absolute;
      width: 86.1%;
      height: 82%;
      top: 48.7%;
      left: 48.9%;
      transform: translate(-50%, -50%);
      margin: auto;
      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }
      &:after {
        content: "";
        position: absolute;
        top: -5px;
        left: -5px;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1;
      }
    }
    .headlines {
      position: absolute;
      top: calc(50% + 2vw);
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      z-index: 10;
      text-align: center;
      h1 {
        color: #fff;
        font-size: 3.5vw;
        margin: 0;
        padding: 0;
      }
      .animate {
        .headline {
          display: block;
          height: 4vw;
          overflow: hidden;
          position: absolute;
          top: -4vw;
          width: 90%;
          left: 5%;
          text-align: center;
          h1 {
            color: $yellow;
            transform: translateY(100px);
            opacity: 0;
            transition: opacity 0.3s, transform 0.5s;
          }
          &.active {
            h1 {
              opacity: 1;
              transform: translate(0);
            }
          }
        }
      }
    }
    .loopPlayer {
      position: absolute;
      height: 30%;
      width: 100%;
      top: 100%;
      left: 80%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
    .rewards {
      position: absolute;
      bottom: -50px;
      left: -120px;
      z-index: 100;
      height: 40%;
      width: 30%;
      cursor: pointer;
      transform: scale(1);
      transition: transform 0.2s;
      &:hover {
        transform: scale(0.95);
      }
    }
  }
  &.noheadlines {
    .tvFrameWrap {
      .tvBackground {
        &:after {
          display: none;
        }
      }
    }
  }
}
@media all and (max-width: 767px) {
  .heroWrapper {
    height: 45vh;
    min-height: unset;
    align-items: flex-start;
    .bg {
      &.studio {
        img {
          object-position: top right;
        }
      }
    }
    .fg {
      .businessesFg1,
      .businessesFg2 {
        min-height: unset;
        height: 105%;
      }
      .businessesFg1 {
        right: 45%;
      }
      .businessesFg2 {
        right: 45%;
      }
      .advertisersFg {
        right: 50%;
        min-height: unset;
        height: 200%;
        top: -50px;
      }
      .artistsFg {
        min-height: unset;
        height: 100%;
        top: auto;
        bottom: -51%;
        width: 150%;
        right: -17%;
      }
    }
    .tvFrameWrap {
      position: relative;
      z-index: 5;
      width: 90%;
      min-width: unset;
      margin: 50px auto 0 auto;
      z-index: 3;
      .tvBackground {
        position: absolute;
        width: 86.1%;
        top: 49.1%;
        left: 48.9%;
        transform: translate(-50%, -50%);
        margin: auto;
      }
      .headlines {
        h1 {
          font-size: 1.6rem;
        }
        .animate {
          .headline {
            height: 1.6rem;
            top: -2rem;
          }
        }
      }
      .loopPlayer {
        position: absolute;
        height: 40%;
        width: 100%;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
      .rewards {
        visibility: hidden;
      }
    }
    .rewards {
      position: absolute;
      top: 80%;
      left: 10%;
      transform: translate(-50%, -50%);
      z-index: 100;
      height: 30%;
      width: 30%;
      z-index: 10;
    }
  }
}
