@import "../../styles/variables.scss";

.gradientBG {
  position: relative;
  width: 100%;
  padding-bottom: 125px;
  overflow: hidden;
  pointer-events: none;
  min-height: 100vh;
  * {
    pointer-events: all;
  }
  &.no_pad {
    padding-bottom: 0;
  }
  .circle1 {
    pointer-events: none;
    position: absolute;
    top: 120px;
    left: -62%;
    width: 135%;
    height: 100%;
    opacity: 0.35;
    background: rgb(252, 204, 18);
    background: radial-gradient(
      circle,
      rgba(252, 204, 18, 0.6055015756302521) 0%,
      rgba(252, 204, 18, 0) 36%
    );
    z-index: 2;
  }
  .circle2 {
    pointer-events: none;
    position: fixed;
    top: -100vh;
    right: -100vw;
    left: auto;
    width: 200vw;
    height: 200vh;
    opacity: 0.35;
    background: rgb(96, 140, 196);
    background: radial-gradient(
      circle,
      rgba(96, 140, 196, 1) 0%,
      rgba(96, 140, 196, 0.006061799719887961) 36%
    );
    z-index: 2;
  }
  .circle3 {
    pointer-events: none;
    position: absolute;
    bottom: -50%;
    right: -62%;
    width: 135%;
    height: 100%;
    opacity: 0.35;
    background: rgb(252, 204, 18);
    background: radial-gradient(
      circle,
      rgba(252, 204, 18, 0.6055015756302521) 0%,
      rgba(252, 204, 18, 0) 36%
    );
    z-index: 2;
  }
  .bg {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $black;
    z-index: 1;
  }
}

@media all and (max-width: 767px) {
  .gradientBG {
    padding-bottom: 50px;
    .circle1,
    .circle2,
    .circle3 {
      display: none;
    }
  }
}
