@import "../../styles/variables.scss";

.newsGrid {
  position: relative;
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 200px 0 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 80px;
  }
  .buttonwrap {
    margin: 50px auto;
  }
}

@media all and (max-width: 767px) {
  .newsGrid {
    padding: 100px 0;
    .grid {
      grid-template-columns: 1fr;
    }
  }
}
