@import "../../styles/variables.scss";

.carouselWrapper {
  position: relative;
  height: 100%;
  //overflow: hidden;
  display: flex;
  justify-content: center;
  margin: 100px auto;
  z-index: 2;
  .carousel {
    position: relative;
    width: 80%;
    padding: 100px 0;
    .items {
      .item {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .image {
          position: relative;
          width: 100%;
          height: 80px;
          display: flex;
          justify-content: center;
          margin-top: 30px;
        }
        .header {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          h1 {
            color: white;
            text-align: center;
            font-size: 3.8rem;
            max-width: 755px;
          }
        }
        .subText {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
          p {
            color: #848484;
            font-size: 1rem;
            margin: 0;
          }
        }
      }
    }
  }
}

@media all and (max-width: 767px) {
  .carouselWrapper {
    margin: 50px auto;
    .carousel {
      width: calc(100% - 70px);
      padding: 50px 0;
      .items {
        .item {
          .image {
            height: 55px;
            margin-bottom: 10px;
          }
          .header {
            h1 {
              font-size: 2rem;
              line-height: 1.2;
            }
          }
          .subText {
            text-align: center;
            p {
              line-height: 1.4;
            }
          }
        }
      }
    }
  }
}
