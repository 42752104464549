@import "../../styles/variables.scss";

.card {
  position: relative;
  margin: 0 auto;
  .media {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    opacity: 1;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.7;
    }
    img {
      border-radius: 12px;
    }
  }
  .info {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .category {
      background-color: $green;
      color: $black;
      font-size: 0.65rem;
      padding: 8px 16px 7px 16px;
      border-radius: 6px;
      font-family: $maax-bold;
      text-transform: uppercase;
    }
    h2 {
      font-size: 1.9rem;
      margin: 20px auto;
      text-align: center;
    }
    h3 {
      font-size: 0.95rem;
      margin: 0 auto 7px auto;
    }
    p {
      color: $graytext;
      font-size: 0.95rem;
      line-height: 25px;
      margin: 10px auto;
      text-align: center;
      a {
        color: $yellow;
        text-decoration: underline;
      }
    }
  }
}
